import React from "react"

import BarStep from "./partials/bar-step"
import AddCarLastService from "../car/add-car-last-service"
import Layout from "../../components/layout";

const AddCarLastServicePage = () => {
  return(
    <div>
      <Layout>
        <BarStep selected="step1" />
        <AddCarLastService navigateTo='/profile/set-dealer-find'/>
      </Layout>
    </div>
  )
};

export default AddCarLastServicePage
